/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-15 10:43:37
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-16 17:01:12
 */
export const columns = [
  {
    title: '大区/事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    width:150,
    align:'center',
    // ellipsis:true
    scopedSlots: { customRender: 'firmName'}
  },
{
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    width:150,
    align:'center',
    // ellipsis:true
},
{
    title: '预订单总数',
    dataIndex: 'total',
    key: 'total',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '首次申请总数',
    dataIndex: 'firstTotal',
    key: 'firstTotal',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '二次申请总数',
    dataIndex: 'secondTotal',
    key: 'secondTotal',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '固定配额',
    dataIndex: 'orderCount',
    key: 'orderCount',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '临时配额',
    dataIndex: 'temporaryQuota',
    key: 'temporaryQuota',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '剩余数量',
    dataIndex: 'surplusPreOrderCount',
    key: 'surplusPreOrderCount',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'surplusPreOrderCount'}
},
{
  title: '剩余可报备数量',
  dataIndex: 'baobeiCount',
  key: 'baobeiCount',
  /*width:150,*/
  align:'center',
  // ellipsis:true
  scopedSlots: { customRender: 'baobeiCount'}
},
]
